import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation
import './ConfirmationPage.css';
import Userfront from '@userfront/toolkit/react';

const ConfirmationPage = () => {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [resendMessage, setResendMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation(); // Use useLocation to get URL params

  useEffect(() => {
    // Get email from URL parameters if redirected from login
    const params = new URLSearchParams(location.search);
    const emailParam = params.get('email');

    setMessage(`An email has been sent to ${emailParam || 'your email address'} with a confirmation link. Click on the link to finish the registration process. Once confirmed, you can go to your dashboard. Please check your spam folder if you don't see it.`);
    setLoading(false);
  }, [location.search]);

  const handleResendEmail = async () => {
    setLoading(true);
    setResendMessage('');

    try {
      // Resend confirmation email using the email from the URL
      const params = new URLSearchParams(location.search);
      const emailParam = params.get('email');

      if (emailParam) {
        await Userfront.sendLoginLink(emailParam);
        setResendMessage('Confirmation email sent successfully. Please check your inbox and spam folder.');
      } else {
        setResendMessage('Email not found in the URL. Please contact support.');
      }
    } catch (error) {
      console.error('Error resending confirmation email:', error);
      setResendMessage('There was an error sending the confirmation email. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="confirmation-page">
    {/**  {loading ? <p>Loading...</p> : <p>{message}</p>} */}
      <button onClick={handleResendEmail} disabled={loading} className="resend-email-button">
        {loading ? 'Sending...' : 'Resend Confirmation Email'}
      </button>
      {resendMessage && <p className="resend-message">{resendMessage}</p>}
      <div>
        <a href="/dashboard" className="dashboard-link">Go to Dashboard</a> | <a href="/contact-support" className="support-link">Contact Support</a>
      </div>
    </div>
  );
};

export default ConfirmationPage;
