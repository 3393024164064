import React, { useState, useEffect } from 'react';
import './CampaignDetail.css';
import Sidebar from '../../components/Sidebar';
import NavBar from '../../components/Navbar';
import axios from 'axios';
import Userfront from "@userfront/toolkit/react";
import { useParams } from 'react-router-dom';
import { usePersistentCollapsedState } from './../../components/SidebarContext';
import SaveRestore from '../../components/TemplateBuilder';

import api from './../../api/axios'; // Import your axios instance

const CampaignDetail = () => {
  const { id } = useParams(); // Get the campaignId from route parameters
  const [selectedLeadListId, setSelectedLeadListId] = useState(''); // State to store selected lead list ID
  const [leadLists, setLeadLists] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [campaign, setCampaign] = useState({}); // State to store campaign data
  const collapsed = usePersistentCollapsedState();
  const [statusMessage, setStatusMessage] = useState(null);

  const fetchLeadLists = async () => {
    try {
      const user = await Userfront.user.email;
      const response = await api.get(`/lead-lists?user=${user}`);
      console.log('Fetched lead lists:', response.data); // Debugging line
      
      setLeadLists(response.data);
    } catch (error) {
      console.error('Error fetching lead lists:', error.response?.data || error.message);
    }
  };
  

  const fetchCampaign = async () => {
    try {
      const response = await api.get(`/campaigns/${id}`);
      setCampaign(response.data);
      console.log('Fetched campaign:', response.data); // Debugging line
      
      if (response.data.leadList) {
        setSelectedLeadListId(response.data.leadList); // Assuming this is the ID
      } else {
        setSelectedLeadListId(''); // Reset if no leadList found
      }
    } catch (error) {
      console.error('Error fetching campaign:', error.response?.data || error.message);
    }
  };
  

  useEffect(() => {
    fetchLeadLists();
    fetchCampaign(); // Fetch campaign data when the component mounts
  }, [id]); // Include id in the dependency array to re-fetch when the ID changes

  const nextStep = () => {
    if (currentStep < 4) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const renderStepTitle = (step) => {
    switch (step) {
      case 1:
        return 'Step 1: Select Lead List';
      case 2:
        return 'Step 2: Configure Workflow';
      case 3:
        return 'Step 3: Configure Settings';
      case 4:
        return 'Step 4: Review & Activate';
      default:
        return '';
    }
  };

  const getProgressBarWidth = (step) => {
    const stepWidth = 25; // Each step has 25% width
    return step <= currentStep ? `${stepWidth}%` : '0%';
  };

  const handleLeadListChange = (e) => {
    const updatedValue = e.target.value;
    setSelectedLeadListId(updatedValue);
  };

  const updateCampaignWithLeadList = async () => {
    try {
      await api.post(`/campaigns/${id}`, {
        leadListId: selectedLeadListId,
      });
      console.log('Campaign updated:');
    } catch (error) {
      console.error('Error updating campaign:', error.response.data);
    }
  };

  const saveConfiguredWorkflow = async (configuredWorkflow) => {
    try {
      await api.post(`/campaigns/${id}/configure-workflow`, {
        configuredWorkflow,
      });
      console.log('Configured workflow saved to the campaign:');
    } catch (error) {
      console.error('Error saving configured workflow:', error.response.data);
    }
  };

  const handleActivateCampaign = async () => {
    try {
      const response = await api.post(`/campaigns/activate/${id}`);
      setStatusMessage(response.data.message);
      fetchCampaign(); // Refresh campaign data
    } catch (error) {
      console.error('Error activating campaign:', error.response.data);
      setStatusMessage('Failed to activate campaign');
    }
  };

  const handlePauseCampaign = async () => {
    try {
      const response = await api.post(`/campaigns/pause/${id}`);
      setStatusMessage(response.data.message);
      fetchCampaign(); // Refresh campaign data
    } catch (error) {
      console.error('Error pausing campaign:', error.response.data);
      setStatusMessage('Failed to pause campaign');
    }
  };

  const handleResumeCampaign = async () => {
    try {
      const response = await api.post(`/campaigns/resume/${id}`);
      setStatusMessage(response.data.message);
      fetchCampaign(); // Refresh campaign data
    } catch (error) {
      console.error('Error resuming campaign:', error.response.data);
      setStatusMessage('Failed to resume campaign');
    }
  };

  const renderActionButton = () => {
    switch (campaign.status) {
      case 'Concept':
        return <button onClick={handleActivateCampaign} className="btn btn-primary">Activate</button>;
      case 'Active':
        return <button onClick={handlePauseCampaign} className="btn btn-warning">Pause</button>;
      case 'Paused':
        return <button onClick={handleResumeCampaign} className="btn btn-success">Resume</button>;
      default:
        return null;
    }
  };

  return (
    <div className={`dashboard ${collapsed ? 'collapsed' : ''}`}>
      <Sidebar />
      <div className="container">
        <NavBar />
        <div className="row">
          <div className="campaign-detail">
            <h1>Campaign Configuration</h1>
            <div className="progress">
              <div className="progress-bar progress-bar-striped bg-info" style={{ width: getProgressBarWidth(1) }}>
                {currentStep === 1 && 'Step 1'}
              </div>
              <div className="progress-bar progress-bar-striped bg-info" style={{ width: getProgressBarWidth(2) }}>
                {currentStep === 2 && 'Step 2'}
              </div>
              <div className="progress-bar progress-bar-striped bg-info" style={{ width: getProgressBarWidth(3) }}>
                {currentStep === 3 && 'Step 3'}
              </div>
              <div className="progress-bar progress-bar-striped bg-info" style={{ width: getProgressBarWidth(4) }}>
                {currentStep === 4 && 'Step 4'}
              </div>
            </div>
            <div className="steps">
              <div className="button-container">
                {currentStep > 1 && (
                  <button className="btn btn-secondary" onClick={prevStep}>
                    Previous
                  </button>
                )}
                {currentStep < 4 && (
                  <button className="btn btn-primary" onClick={nextStep}>
                    Next
                  </button>
                )}
              </div>
              <div className={`step ${currentStep === 1 ? 'active' : ''}`}>
                {currentStep === 1 && (
                  <div>
                    <h2>1. Select leads</h2>
                    <div className="filter-controls">
                      <select
                        className="form-control"
                        value={campaign.leadListId}
                        onChange={handleLeadListChange}
                      >
                        <option value="">Select a Lead List</option>
                        {leadLists.map((leadList) => (
                          <option key={leadList._id} value={leadList._id}>
                            {leadList.leadListTitle}
                          </option>
                        ))}
                      </select>
                    </div>
                    <button
                      className="btn btn-primary saveLeadListCampaignButton"
                      onClick={updateCampaignWithLeadList}
                    >
                      Save Selected Lead List
                    </button>
                  </div>
                )}
              </div>
              <div className={`step ${currentStep === 2 ? 'active' : ''}`}>
                {currentStep === 2 && (
                  <div>
                    <h2>2. Configure workflow</h2>
                    <SaveRestore id={id} saveWorkflow={saveConfiguredWorkflow} />
                  </div>
                )}
              </div>
              <div className={`step ${currentStep === 3 ? 'active' : ''}`}>
                {currentStep === 3 && (
                  <div>
                    <h2>3. Configure campaign settings</h2>
                  </div>
                )}
              </div>
              <div className={`step ${currentStep === 4 ? 'active' : ''}`}>
                {currentStep === 4 && (
                  <div>
                    <h2>4. Review & Activate</h2>
                    {renderActionButton()}
                    {statusMessage && <p>{statusMessage}</p>}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignDetail;
