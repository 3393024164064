import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import placeholderImage from './../assets/placeholder-image.png';
import './Navbar.css';

import Userfront from "@userfront/toolkit/react";
import api from './../api/axios';

import {
  FaHome,
  FaChartBar,
  FaEnvelope,
  FaUserPlus,
  FaCog,
  FaUsers,
  FaCreditCard,
  FaBriefcase,
  FaSignOutAlt,
  FaChevronDown,
  FaQuestion
} from 'react-icons/fa';

import { Tooltip, OverlayTrigger } from 'react-bootstrap';


const NavBar = () => {
  const navigate = useNavigate();
  const currentUser = Userfront.user?.email;

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userEmail = Userfront.user.email;
        if (!userEmail) {
          console.error('User email is not available.');
          setLoading(false);
          return;
        }

        const userResponse = await api.get(`/user?email=${userEmail}`);
        const currentUser = userResponse.data.user;
        setUser(currentUser);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user:', error);
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  const isAdmin = user?.role === 'Admin';
  const hasTeamSubscription = user?.subscriptionType === 'Team';
  const hasBasicSubscription = user?.subscriptionType === 'Basic';
  const userStatusIsActive = user?.status === 'Active';
  const canUpgradeToPro = isAdmin && user?.subscriptionType === 'trial';

  const handleLogout = async () => {
    try {
      await api.post('/logout');
      await Userfront.logout();
      localStorage.removeItem('authToken');
      sessionStorage.removeItem('userData');
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" className='custom-tooltip' {...props}>
      Open help center
    </Tooltip>
  );

  return (
    <div>
      <Navbar bg="light" expand="lg" className="topNavBar">
        <Navbar.Brand href="/dashboard" className="d-lg-none">
          <img src="/logo.png" alt="Your Logo" className="logo-mobile" />
        </Navbar.Brand>

        {/* Main container for support icon and user dropdown */}
        <Nav className="ms-auto topNavBarRight d-flex align-items-center">
          {/* Wrapper for icon and dropdown to ensure horizontal alignment */}
          <div className="d-flex align-items-center">
            {/* Support icon */}
            <OverlayTrigger
              placement="left" // You can adjust the placement to 'bottom', 'left', 'right', etc.
              overlay={renderTooltip}
            >
            <div className="support-icon-wrapper flex items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4 me-3">
              <a href='http://support.leadgenflow.io' target="_blank" rel="noopener noreferrer">
                <FaQuestion className="support-icon" />
              </a>
            </div>
            </OverlayTrigger>

            {/* User Dropdown */}
            <NavDropdown
              title={
                <div className="user-info d-flex align-items-center">
                  {/* User Details */}
                  <div className="user-details">
                    <div className="user-name">{user?.name}</div>
                    <div className="user-role stats-span font-medium">{user?.role}</div>
                  </div>
                  {/* User Initial */}
                  <div className="user-initial">{currentUser?.charAt(0).toUpperCase()}</div>
                  {/* Dropdown Icon */}
                  <div className="dropdown-icon">
                    <FaChevronDown className="custom-caret" />
                  </div>
                </div>
              }
              id="basic-nav-dropdown"
              className="custom-dropdown no-caret"
            >
              <NavDropdown.Item as={Link} to="/">
                <FaHome className="menu-icon menu-icon-blue" /> Dashboard
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/campaigns">
                <FaChartBar className="menu-icon menu-icon-blue" /> Campaigns
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/messages">
                <FaEnvelope className="menu-icon menu-icon-blue" /> Messages
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/leads">
                <FaBriefcase className="menu-icon menu-icon-blue" /> Leads
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/settings">
                <FaCog className="menu-icon menu-icon-blue" /> Settings
              </NavDropdown.Item>
              {isAdmin && hasTeamSubscription && (
                <NavDropdown.Item as={Link} to="/team">
                  <FaUsers className="menu-icon menu-icon-blue" /> Team
                </NavDropdown.Item>
              )}
              {isAdmin && (
                <NavDropdown.Item as={Link} to="/subscription">
                  <FaCreditCard className="menu-icon menu-icon-blue" /> Billing
                </NavDropdown.Item>
              )}
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={handleLogout}>
                <FaSignOutAlt className="menu-icon menu-icon-blue" /> Logout
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </Nav>
      </Navbar>
    </div>
  );
};

export default NavBar;
