import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Messages.css'; // Style file for component
import Sidebar from './../../components/Sidebar';
import NavBar from './../../components/Navbar';
import { FaEnvelope } from 'react-icons/fa'; // Import Font Awesome icon for envelope

import api from './../../api/axios'; // Import your axios instance

const Messages = () => {
  const [leads, setLeads] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [messages, setMessages] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // State for search input

  useEffect(() => {
    fetchLeads();
  }, []);

  const fetchLeads = async () => {
    try {
      const response = await api.get('/messages/leads');
      setLeads(response.data);
    } catch (error) {
      console.error('Error fetching leads:', error);
    }
  };

  const fetchMessages = async (leadName) => {
    try {
      const response = await api.get(`/messages/messages/${leadName}`);
      setMessages(response.data);
      setSelectedLead(leadName);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const markMessagesAsRead = async (leadName) => {
    try {
      const response = await api.post(`/messages/mark-read/${leadName}`);
      console.log('Messages marked as read:', response.data);
      // Refetch messages to update read status visually
      fetchMessages(leadName);
    } catch (error) {
      console.error('Error marking messages as read:', error);
    }
  };

  const countUnreadMessages = (leadName) => {
    if (selectedLead === leadName) {
      return 0; // No unread messages in the current selected lead
    }
    const lead = leads.find((lead) => lead.leadName === leadName);
    if (!lead) {
      return 0; // Lead not found
    }
    return lead.messages.filter((message) => message.status === 'unread').length;
  };

  // Filter leads based on the search term
  const filteredLeads = leads.filter((lead) =>
    lead.leadName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="dashboard">
      <Sidebar />
      <div className="container">
        <NavBar />
        <div className="row">


          <div className='col-md-12'>

            <div className='message-widget'>

            
            <div className="row">
  
     
          <div className="col-md-3">
            <h2>Inbox</h2>
            {/* Search Input */}
            <input
              type="text"
              placeholder="Search by lead name..."
              className="lead-search-input"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
            />
            {/* Lead List */}
            <ul className="lead-list">
              {filteredLeads.map((lead, index) => (
                <li key={index} className="lead-item" onClick={() => fetchMessages(lead.leadName)}>
                  {/* Image or Initial */}
                  {lead.profileImageUrl ? (
                    <img
                      src={lead.profileImageUrl}
                      alt="Lead Avatar"
                      className="lead-avatar"
                    />
                  ) : (
                    <div className="lead-initial">
                      {lead.leadName.charAt(0).toUpperCase()} {/* Display the first letter */}
                    </div>
                  )}
                  {/* Lead Name */}
                  <span className="lead-name">{lead.leadName}</span>
                  {/* Unread Badge */}
                  {countUnreadMessages(lead.leadName) > 0 && (
                    <span className="unread-badge">
                      {countUnreadMessages(lead.leadName)}
                    </span>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="col-md-9">
            <h2></h2>
            {selectedLead && (
              <div className="">
                {messages.map((message, index) => (
                  <div
                    key={index}
                    className={`message ${message.status === 'read' ? 'read' : 'unread'}`}
                    onClick={() => markMessagesAsRead(selectedLead)}
                  >
                    <div className="sender">{message.sender}</div>
                    <div className="text">{message.text}</div>
                  </div>
                ))}
              </div>
            )}
          </div>
</div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Messages;
