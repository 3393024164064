// Pagination.js
import React from 'react';
import './Leads.css'; // Ensure you have styling for pagination

const Pagination = ({ currentPage, totalItems, recordsPerPage, paginate }) => {
  const totalPages = Math.ceil(totalItems / recordsPerPage);
  const maxPageButtons = 5; // Maximum number of page buttons to show at once

  const getPageNumbers = () => {
    const pageNumbers = [];

    // Calculate the start and end page numbers
    let startPage = Math.max(currentPage - Math.floor(maxPageButtons / 2), 1);
    let endPage = startPage + maxPageButtons - 1;

    // Adjust if the end page exceeds the total pages
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(endPage - maxPageButtons + 1, 1);
    }

    // Push the page numbers into the array
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  const pageNumbers = getPageNumbers();

  return (
    <div className="pagination">
      {/* Double Previous Button */}
      {currentPage > 5 && (
        <button
          onClick={() => paginate(Math.max(currentPage - 5, 1))}
          className="pagination-button"
        >
          &laquo; {/* Double Left Arrow */}
        </button>
      )}

      {/* Previous Button */}
      {currentPage > 1 && (
        <button onClick={() => paginate(currentPage - 1)} className="pagination-button">
          &lsaquo; {/* Left Arrow */}
        </button>
      )}

      {/* Page Numbers */}
      {pageNumbers.map((number) => (
        <button
          key={number}
          onClick={() => paginate(number)}
          className={`pagination-button ${currentPage === number ? 'active' : ''}`}
        >
          {number}
        </button>
      ))}

      {/* Next Button */}
      {currentPage < totalPages && (
        <button onClick={() => paginate(currentPage + 1)} className="pagination-button">
          &rsaquo; {/* Right Arrow */}
        </button>
      )}

      {/* Double Next Button */}
      {currentPage <= totalPages - 5 && (
        <button
          onClick={() => paginate(Math.min(currentPage + 5, totalPages))}
          className="pagination-button"
        >
          &raquo; {/* Double Right Arrow */}
        </button>
      )}
    </div>
  );
};

export default Pagination;
