import React, { useState, useEffect } from 'react';
import './Leads.css';
import Sidebar from './../../components/Sidebar';
import NavBar from './../../components/Navbar';
import { Modal, Button, Form, Spinner, Alert } from 'react-bootstrap';

import { Dropdown, DropdownButton } from 'react-bootstrap';

import axios from 'axios';
import Userfront from "@userfront/toolkit/react";

import { usePersistentCollapsedState } from './../../components/SidebarContext'; // Import useSidebar

import { CSVLink } from "react-csv"; // Import CSVLink from react-csv

import api from './../../api/axios'; // Import your axios instance

import { FaLinkedin, FaFileCsv, FaFileExport, FaTasks } from 'react-icons/fa';

import Pagination from './Pagination'; // Import the new Pagination component


function Leads() {

  const collapsed = usePersistentCollapsedState();

  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

// Import the csv-export library


  const [leadLists, setLeadLists] = useState([]);

  const [leads, setLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedListFilter, setSelectedListFilter] = useState('all');
  const [selectedStatusFilter, setSelectedStatusFilter] = useState('all');
  const [recordsPerPage, setRecordsPerPage] = useState(25); // Default records per page

  const [showModal, setShowModal] = useState(false);
  const [newLead, setNewLead] = useState({
    name: '',
    organization: '',
    leadList: 'Default',
    status: 'active',
  });

  const [currentPage, setCurrentPage] = useState(1);

  const [showNewLeadListModal, setShowNewLeadListModal] = useState(false);
  const [showNewLeadListCsvModal, setShowNewLeadListCsvModal] = useState(false);
  const [newLeadList, setNewLeadList] = useState({
    user: Userfront.user.email,
    leadListTitle: '',
    linkedinSearchUrl: '',
    maxQuantity: 10,
  });




  const [leadsFromCsv, setLeadsFromCsv] = useState([]);

  const handleCsvUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      console.error('No file selected.');
      return;
    }
  
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      console.log('CSV Content:', text); // Log the CSV content for debugging
      const json = csvToJson(text);
      console.log('Parsed JSON:', json); // Log the parsed JSON to verify the structure
      setLeadsFromCsv(json);
    };
    reader.readAsText(file);
  };
  
  
  

  const csvToJson = (csv) => {
    // Split CSV content by new lines
    const lines = csv.trim().split('\n');
  
    // Determine the delimiter by checking the first line
    const delimiter = lines[0].includes(';') ? ';' : ',';
  
    // Extract headers from the first line using the determined delimiter
    const headers = lines[0].split(delimiter).map(header => header.trim());
  
    // Initialize an array to hold the parsed data
    const result = [];
  
    // Process each line after the headers
    for (let i = 1; i < lines.length; i++) {
      const line = lines[i].trim();
  
      // Skip empty lines
      if (!line) continue;
  
      // Split line by the determined delimiter and handle extra spaces
      const currentline = line.split(delimiter).map(field => field.trim());
  
      // Check if the number of fields matches the headers
      if (currentline.length === headers.length) {
        const obj = {};
        // Map headers to fields
        for (let j = 0; j < headers.length; j++) {
          obj[headers[j]] = currentline[j];
        }
        result.push(obj);
      } else {
        console.warn(`Skipping invalid line: ${line}`);
      }
    }
  
    console.log('Parsed JSON:', result);
    return result;
  };
  
  
  
  
  
  
  





  useEffect(() => {
    // Simulate fetching leads from your backend or another data source
    const fetchedLeads = [
      { _id: '1', name: 'Lead 1', organization: 'Org 1', leadList: 'List 1', status: 'active' },
      { _id: '2', name: 'Lead 2', organization: 'Org 2', leadList: 'List 2', status: 'inactive' },
      { _id: '3', name: 'Lead 3', organization: 'Org 3', leadList: 'List 1', status: 'active' },
      // Add more leads here
    ];

    // Ensure the leads state is properly updated
    setLeads(fetchedLeads);
    setFilteredLeads(fetchedLeads); // Initialize filteredLeads with all leads
  }, []); // Empty dependency array ensures this runs once on component mount

  const handleManageClick = (leadId) => {
    // Handle the "Manage" button click for the selected lead
    console.log(`Managing lead with ID ${leadId}`);
  };

  // Calculate the index of the last lead on the current page
  const indexOfLastLead = currentPage * recordsPerPage;
  // Calculate the index of the first lead on the current page
  const indexOfFirstLead = indexOfLastLead - recordsPerPage;
  // Get the leads for the current page
  const currentLeads = filteredLeads.slice(indexOfFirstLead, indexOfLastLead);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page when changing records per page
  };

  // Handle search input change and filter leads based on the search term (Name column)
  useEffect(() => {
    if (searchTerm) {
      const filtered = leads.filter((lead) =>
        (lead.leadName ? lead.leadName.toLowerCase() : '').includes(searchTerm.toLowerCase())
      );
      setFilteredLeads(filtered);
    } else {
      // If searchTerm is undefined or empty, show all leads
      setFilteredLeads(leads);
    }
  }, [searchTerm, leads]);
  

  // Filter the leads based on the selected list and status filters
  useEffect(() => {
    const filtered = leads.filter((lead) => {
      if (selectedListFilter !== 'all' && lead.leadListTitle !== selectedListFilter) {
        return false;
      }
      return true;
    });
    setFilteredLeads(filtered);
    setCurrentPage(1); // Reset to the first page when changing the filter
  }, [selectedListFilter, leads]);

  const handleNewLeadSubmit = async () => {
    try {
      // Fetch the current user data from Userfront
      const user = await Userfront.currentUser();
  
      const response = await api.post('/leads', {
        name: newLead.name,
        organization: newLead.organization,
        leadList: newLead.leadList,
        status: newLead.status,
        user: user.email, // Include the user data
      });
  
      // Handle success
      console.log('New lead submitted:', response.data);
  
      // Close the modal
      setShowModal(false);
    } catch (error) {
      console.error('Error creating new lead:', error.response.data);
    }
  };


  // Fetch lead lists for the current user

    const fetchLeadLists = async () => {
      try {
        // Fetch the current user data from Userfront
        const user = await Userfront.user.email;

        // Make an API request to fetch lead lists for the user
        const response = await api.get(`/lead-lists?user=${user}`);

        // Update the lead lists state with the data from the backend
        setLeadLists(response.data);
      } catch (error) {
        console.error('Error fetching lead lists:', error.response.data);
      }
    };

  useEffect(() => {
    fetchLeadLists();
  }, []);

  const handleNewLeadListSubmit = async () => {
    try {

      setIsLoading(true); // Set loading state to true


      // Fetch the current user data from Userfront
 
      const response = await api.post('/leads', {
        user: Userfront.user.email,
        leadListTitle: newLeadList.leadListTitle,
        linkedinSearchUrl: newLeadList.linkedinSearchUrl,
        maxQuantity: newLeadList.maxQuantity,

      });
  
      // Handle success
      console.log('New lead submitted:', response.data);

      

   //   this.fetchLeadLists();

   
      // Close the modal
      setShowNewLeadListModal(false);

      // Set success message and clear error message
      setSuccessMessage('Leadlist created successfully');
      setErrorMessage('');


    //  window.location.reload(false)
     fetchLeadLists();
     fetchLeads();

    } catch (error) {
      console.error('Error creating new lead:', error.response.data);

      // Set error message and clear success message
      setErrorMessage('Error creating lead');
      setSuccessMessage('');

    } finally {
      setIsLoading(false); // Reset loading state
    }
  };


  

  const handleNewLeadListSubmitCsv = async () => {
    try {
      setIsLoading(true); // Set loading state to true
  
      const response = await api.post('/leads-from-json', {
        user: Userfront.user.email,
        leadListTitle: newLeadList.leadListTitle,
        maxQuantity: newLeadList.maxQuantity,
        leads: leadsFromCsv, // Add the parsed CSV leads data
      });
  
      console.log('New leads from CSV submitted:', response.data);
  
      // Close the modal
      setShowNewLeadListCsvModal(false);
  
      // Set success message and clear error message
      setSuccessMessage('Lead list created successfully');
      setErrorMessage('');
  
      fetchLeadLists();
      fetchLeads();
  
    } catch (error) {
      console.error('Error creating new lead list from CSV:', error.response.data);
  
      // Set error message and clear success message
      setErrorMessage('Error creating lead list from CSV');
      setSuccessMessage('');
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };
  



  // Fetch leads for the current user
    const fetchLeads = async () => {
      try {
        // Fetch the current user data from Userfront
        const user = Userfront.user.email;

        // Make an API request to fetch leads for the user
        const response = await api.get(`/leads?user=${user}`);

        // Update the leads state with the data from the backend
        setLeads(response.data);
        setFilteredLeads(response.data); // Initialize filteredLeads with all leads
      } catch (error) {
        console.error('Error fetching leads:', error.response.data);
      }
    };


  useEffect(() => {
    fetchLeads();
  }, []);



  // Function to export leads data to CSV
  const exportLeadsToCSV = () => {
    const csvData = filteredLeads.map((lead) => ({
      id: lead._id,
      leadName: lead.leadName,
      profileUrl: lead.profileUrl,
      leadList: lead.leadListTitle,
    }));

    return csvData;
  };

  

  return (
    <div className={`dashboard`}>
      <Sidebar />
      <div className="container">
        <NavBar />
        <div className="row">
        <div className="col-md-12">
          <div className='activities-widget'>
            
              <h2>Leads</h2>

              {/* Show spinner while loading */}
              {isLoading && <Spinner animation="border" role="status" />}
              {/* Show success or error message */}
              {successMessage && <Alert variant="success">{successMessage}</Alert>}
              {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

              {/* Filter Section */}
              <div className="row">
                <div className='col-md-3 col-settings'>
                  <div className="search-bar">
                    <input
                      className='form-control'
                      type="text"
                      placeholder="Search Leads"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
                <div className='col-md-3 col-settings'>
                  <div className="filter-controls">
                    <select
                      className='form-control'
                      value={selectedListFilter}
                      onChange={(e) => setSelectedListFilter(e.target.value)}
                    >
                      <option value="all">Select Leadlist</option>
                      {leadLists.map((leadList) => (
                        <option key={leadList._id} value={leadList.leadListTitle}>
                          {leadList.leadListTitle}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className='col-md-6 col-settings'>
                  <div className="create-button float-right">
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={
                        <>
                          <FaTasks style={{ marginRight: '8px' }} /> Manage Leads
                        </>
                      }
                      variant="primary"
                      className="importButtonLeads"
                    >
                      <Dropdown.Item onClick={() => setShowNewLeadListModal(true)}>
                        <FaLinkedin style={{ marginRight: '8px', color: '#0e76a8' }} /> Import from LinkedIn
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setShowNewLeadListCsvModal(true)}>
                        <FaFileCsv style={{ marginRight: '8px', color: '#28a745' }} /> Import from CSV
                      </Dropdown.Item>
                      <Dropdown.Item as="div">
                        <CSVLink
                          data={exportLeadsToCSV()}
                          filename={"leads.csv"}
                          className="btn btn-link p-0 text-dark"
                        >
                          <FaFileExport style={{ marginRight: '8px', color: '#007bff' }} /> Export to CSV
                        </CSVLink>
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className='col-md-12 col-settings'>
                  {/* Table */}
                  <div className='table-responsive'>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Image</th>
                          <th>Name</th>
                          <th>Profile URL</th>
                          <th>Lead List</th>
                          <th>Manage</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentLeads.map((lead) => (
                          <tr key={lead._id}>
                            <td>
                              {lead.profileImageUrl &&
                                <img
                                  src={lead.profileImageUrl}
                                  alt="Lead Avatar"
                                  width="80"
                                  height="80"
                                />
                              }
                            </td>
                            <td>{lead.leadName}</td>
                            <td>{lead.profileUrl}</td>
                            <td>{lead.leadListTitle}</td>
                            <td>
                              <button className='btn btn-primary' onClick={() => handleManageClick(lead._id)}>View</button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className='col-md-6'>
                <div className="records-per-page"><span>Rows per page: </span>
                  <select
                    className=""
                    value={recordsPerPage}
                    onChange={handleRecordsPerPageChange}
                  >
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                  </select>
                </div>
              
              </div>
              <div className='col-md-6'>
              {/* Pagination */}
              <Pagination
              className="float-right"
                currentPage={currentPage}
                totalItems={filteredLeads.length}
                recordsPerPage={recordsPerPage}
                paginate={paginate}
              />
              </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      {/* Modal for adding a new lead */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Lead</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={newLead.name}
                onChange={(e) => setNewLead({ ...newLead, name: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Organization</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter organization"
                value={newLead.organization}
                onChange={(e) => setNewLead({ ...newLead, organization: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Lead List</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter lead list"
                value={newLead.leadList}
                onChange={(e) => setNewLead({ ...newLead, leadList: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                value={newLead.status}
                onChange={(e) => setNewLead({ ...newLead, status: e.target.value })}
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleNewLeadSubmit}>
            Create Lead
          </Button>
        </Modal.Footer>
      </Modal>

        {/* Modal for adding a new lead list */}
        <Modal show={showNewLeadListModal} onHide={() => setShowNewLeadListModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Lead List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={newLeadList.leadListTitle}
                onChange={(e) => setNewLeadList({ ...newLeadList, leadListTitle: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>LinkedIn Search URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter LinkedIn Search URL"
                value={newLeadList.linkedinSearchUrl}
                onChange={(e) =>
                  setNewLeadList({ ...newLeadList, linkedinSearchUrl: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Max Quantity</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter max quantity"
                value={newLeadList.maxQuantity}
                onChange={(e) =>
                  setNewLeadList({ ...newLeadList, maxQuantity: parseInt(e.target.value) })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowNewLeadListModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleNewLeadListSubmit}>
            Create Lead List
          </Button>
        </Modal.Footer>
      </Modal>


      {/* Modal for uploading a CSV file */}
      <Modal show={showNewLeadListCsvModal} onHide={() => setShowNewLeadListCsvModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Lead List (Upload CSV)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={newLeadList.leadListTitle}
                onChange={(e) => setNewLeadList({ ...newLeadList, leadListTitle: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Upload CSV</Form.Label>
              <Form.Control type="file" accept=".csv" onChange={handleCsvUpload} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Max Quantity</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter max quantity"
                value={newLeadList.maxQuantity}
                onChange={(e) =>
                  setNewLeadList({ ...newLeadList, maxQuantity: parseInt(e.target.value) })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowNewLeadListCsvModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleNewLeadListSubmitCsv}>
            Create Lead List
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
}

export default Leads;
