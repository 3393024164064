import React, { useState } from 'react';
import './ResetPassword.css';
import axios from 'axios';
import Sidebar from '../../components/Sidebar';
import NavBar from '../../components/Navbar';
import Userfront, { PasswordResetForm } from "@userfront/toolkit/react";



function ResetPassword() {


  return (

      <div className='container'>
        <div className="row">

          <div className="Login">
          <img className='logo' src="/logo.png" alt="LeadgenFlow Logo" />
           
          <PasswordResetForm/>
        
           
          </div>
        </div>
      </div>
  );
}

export default ResetPassword;
