import React, { useState, useEffect } from 'react';
import './Settings.css';
import Sidebar from './../../components/Sidebar';
import NavBar from '../../components/Navbar';
import countries from '../../components/Countries';
import { usePersistentCollapsedState } from './../../components/SidebarContext';
import Userfront from '@userfront/toolkit/react';
import api from './../../api/axios'; // Import your axios instance

function Settings() {
  const collapsed = usePersistentCollapsedState();
  const [email, setEmail] = useState(Userfront.user.email);
  const [password, setPassword] = useState('');
  const [connectionLimit, setConnectionLimit] = useState(0);
  const [profileViewLimit, setProfileViewLimit] = useState(0);
  const [invoicingDetails, setInvoicingDetails] = useState({
    companyName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    postalCode: '',
    country: '',
    state: '',
    taxId: '',
    taxIdType: 'eu_vat', // Default tax ID type, can be adjusted based on available options
  });
  const [organizationId, setOrganizationId] = useState('');
  const [error, setError] = useState('');
  
  const userInUserfront = Userfront.user;
  const [user, setUser] = useState(null);
  const isAdmin = user?.role === 'Admin';
  const [loading, setLoading] = useState(true);

  const [activeTab, setActiveTab] = useState('saveCookie');


  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const userEmail = Userfront.user.email;
        if (!userEmail) {
          console.error('User email is not available.');
          return;
        }

        const userResponse = await api.get(`/user?email=${userEmail}`);
        const currentUser = userResponse.data.user;
        setOrganizationId(currentUser.organizationId);
        setConnectionLimit(currentUser.connectionLimit || 100);
        setProfileViewLimit(currentUser.profileViewLimit || 50);

        const orgResponse = await api.get(`/organizations/${currentUser.organizationId}`);
        setInvoicingDetails(orgResponse.data.invoicingDetails || {});
      } catch (error) {
        console.error('Error fetching settings:', error);
        setError('Error fetching settings: ' + error.response?.data?.error || 'Unknown error');
      }
    };

    fetchSettings();
  }, [email]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userEmail = Userfront.user.email;
        if (!userEmail) {
          console.error('User email is not available.');
          setLoading(false);
          return;
        }

        const userResponse = await api.get(`/user?email=${userEmail}`);
        const currentUser = userResponse.data.user;
        setUser(currentUser);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user:', error);
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  const handleOpenLinkedInPopup = () => {
    const popupUrl = `${window.location.origin}/popup.html`;
    window.open(popupUrl, 'linkedin-login', 'width=600,height=600');
  };

  const handleSaveCookie = async () => {
    try {
      // Simulating cookies object containing li_at cookie
   //   const cookies = { li_at: 'some_li_at_cookie_value' };

      const response = await api.post('/save-cookie', {
        email: email,
        password: password,
      });
      console.log(response.data);

      // Clear the input fields after saving the cookie
      setPassword('');
      setError('');
    } catch (error) {
      console.error('Error saving cookie:', error.response.data);
      setError('Error saving cookie: ' + error.response.data.error);
    }
  };

  const handleSaveLimits = async () => {
    try {
      const response = await api.put('/users/limits', {
        email: email,
        connectionLimit: connectionLimit,
        profileViewLimit: profileViewLimit,
      });
      console.log('Limits saved:', response.data);
    } catch (error) {
      console.error('Error saving limits:', error.response?.data || error);
      setError('Error saving limits: ' + error.response?.data?.error || 'Unknown error');
    }
  };

  return (
    <div className={`dashboard ${collapsed ? 'collapsed' : ''}`}>
      <Sidebar />
      <div className='container'>
        <NavBar />
        <div className="row">
        <div className='col-md-12'>
        <div className='activities-widget'>
          <h2>Settings</h2>
        <div className="tabs">
                  <button className={activeTab === 'saveCookie' ? 'tab active' : 'tab'} onClick={() => setActiveTab('saveCookie')}>Connect LinkedIn</button>
                  <button className={activeTab === 'saveLimits' ? 'tab active' : 'tab'} onClick={() => setActiveTab('saveLimits')}>Manage Limits</button>
              </div>
          <div className='col-md-6'>
            <div className="Settings">
            {activeTab === 'saveCookie' && (
              <div>
              <h3>Connect LinkedIn Account</h3>
              {error && <div className="error-message">{error}</div>}
              <input
                className='form-control'
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled
              />
              <input
                className='form-control'
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button className='btn btn-primary' onClick={handleSaveCookie}>Save LinkedIn Cookie</button>
              <button className='btn btn-secondary' onClick={handleOpenLinkedInPopup}>Login with LinkedIn</button>
              </div>
              )}
              {activeTab === 'saveLimits' && (
              <div className="limits-form">
                <h2>Manage daily limits</h2>
                <div className="form-group">
                  <label htmlFor="connectionLimit">Connection Request Limit</label>
                  <input
                    className='form-control'
                    type="range"
                    id="connectionLimit"
                    min="0"
                    max="100"
                    step="10"
                    value={connectionLimit}
                    onChange={(e) => setConnectionLimit(e.target.value)}
                  />
                  <div className="slider-value">
                    {connectionLimit}
                  </div>
                </div>
                
                <div className="form-group">
                  <label htmlFor="profileViewLimit">Profile View Limit</label>
                  <input
                    className='form-control'
                    type="range"
                    id="profileViewLimit"
                    min="0"
                    max="50"
                    step="10"
                    value={profileViewLimit}
                    onChange={(e) => setProfileViewLimit(e.target.value)}
                  />
                  <div className="slider-value">
                    {profileViewLimit}
                  </div>
                </div>
                
                <button className='btn btn-primary' onClick={handleSaveLimits}>Save Limits</button>
              </div>
              )}
            </div>
          </div>
        </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
