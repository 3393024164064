import React, { useState, useEffect } from 'react';
import './Sidebar.css'; // Import your custom CSS file
import { useSidebar } from './SidebarContext';
import axios from 'axios';
import Userfront from '@userfront/toolkit/react';
import api from './../api/axios'; // Import your axios instance

import {
  FaHome,
  FaChartBar,
  FaEnvelope,
  FaUserPlus,
  FaCog,
  FaUsers,
  FaCreditCard,
  FaBriefcase,
} from 'react-icons/fa'; // Import Font Awesome icons

const Sidebar = () => {
  const { collapsed, toggleSidebar } = useSidebar();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userEmail = Userfront.user.email;
        if (!userEmail) {
          console.error('User email is not available.');
          setLoading(false);
          return;
        }

        // Fetch current user details from the database using email
        const userResponse = await api.get(`/user?email=${userEmail}`);
        const currentUser = userResponse.data.user;
        setUser(currentUser);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user:', error);
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  if (loading) {
  //  return <div>Loading...</div>;
  }

  const isAdmin = user?.role === 'Admin';
  const hasTeamSubscription = user?.subscriptionType === 'Team';
  const hasBasicSubscription = user?.subscriptionType === 'Basic';
  const userStatusIsActive = user?.status === 'Active';
  const canUpgradeToPro = isAdmin && user?.subscriptionType === 'trial';

  return (
    <div className={`sidebar ${collapsed ? 'collapsed' : 'uncollapsed'}`}>
      <div className="sidebar-logo">
        <a href='/dashboard'>
          <img src="/logo.png" alt="Your Logo" />
        </a>
      </div>

      <div className="hamburger" onClick={toggleSidebar}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>

      <ul className="sidebar-menu">
        <li>
          <a href="/">
            <FaHome className="menu-icon" /> {!collapsed && <span><b>Dashboard</b></span> }
          </a>
        </li>
        <li>
          <a href="/campaigns">
            <FaChartBar className="menu-icon" /> {!collapsed && <span><b>Campaigns</b></span> }
          </a>
        </li>
        <li>
          <a href="/messages">
            <FaEnvelope className="menu-icon" /> {!collapsed && <span><b>Inbox</b></span> }
          </a>
        </li>
        <li>
          <a href="/leads">
            <FaBriefcase className="menu-icon" /> {!collapsed && <span><b>Leads</b></span> }
          </a>
        </li>
        <li>
          <a href="/settings">
            <FaCog className="menu-icon" /> {!collapsed && <span><b>Settings</b></span> }
          </a>
        </li>
        {isAdmin && hasTeamSubscription && (
          <>
            <li>
              <a href="/team">
                <FaUsers className="menu-icon" /> {!collapsed && <span><b>Team members</b></span> }
              </a>
            </li>
        </>
        )}
        {isAdmin && (
          <>
            <li>
              <a href="/subscription">
                <FaCreditCard className="menu-icon" /> {!collapsed && <span><b>Billing</b></span> }
              </a>
            </li>
          </>
        )}
      </ul>

      {!collapsed && canUpgradeToPro && (
        <div className="subscription-cta">
          <a href="/subscribe">
            <FaUserPlus className="cta-icon" /> Upgrade to Pro
          </a>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
