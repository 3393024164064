// src/App.js
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom'; // Import Router
import './App.css';
import AppRouter from './AppRouter';

import Userfront from "@userfront/toolkit/react";
import { SidebarProvider } from './components/SidebarContext';
import { ReactFlowProvider } from 'reactflow';

import SubscriptionRedirect from './components/SubscriptionRedirect';

Userfront.init("9nym64vn");

function App() {
  return (
    <Router> {/* Wrap with Router */}
      <ReactFlowProvider>
        <SubscriptionRedirect />
        <SidebarProvider>
          <div className="App">
            <header className="App-header">
              <AppRouter />
            </header>
          </div>
        </SidebarProvider>
      </ReactFlowProvider>
    </Router>
  );
}

export default App;
