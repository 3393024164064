import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Form, Button, Dropdown } from 'react-bootstrap';
import ReactFlow, {
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  Panel,
} from 'reactflow';
import 'reactflow/dist/style.css';
import api from './../api/axios'; // Import your axios instance
import './TemplateBuilder.css';

const getNodeId = () => `randomnode_${+new Date()}`;

const initialNodes = [
  { id: '1', data: { label: 'View profile' }, position: { x: 100, y: 100 } },
  { id: '2', data: { label: 'Timer' }, position: { x: 100, y: 200 } },
  { id: '3', data: { label: 'Send connection request' }, position: { x: 100, y: 300 } },
];

const initialEdges = [
  { id: 'e1-2', source: '1', target: '2' },
  { id: 'e2-3', source: '2', target: '3' },
];

const SaveRestore = ({ id }) => {
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [rfInstance, setRfInstance] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [nodeSettings, setNodeSettings] = useState({});
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { setViewport } = useReactFlow();

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

  const onNodeClick = useCallback((event, node) => {
    setSelectedNode(node);
    setNodeSettings(node.data || {});
    setShowSuccessMessage(false); // Reset success message on node click
  }, []);

  const onSave = useCallback(() => {
    if (rfInstance) {
      const flow = rfInstance.toObject();
      saveConfiguredWorkflow({ workflow: flow, id });
    }
  }, [rfInstance, id]);

  const onRestore = useCallback(() => {
    const restoreFlow = async () => {
      const savedWorkflow = await getConfiguredWorkflow(id);

      if (!savedWorkflow) {
        console.log('No saved workflow found in the backend.');
        return;
      }

      try {
        const { viewport, nodes: savedNodes, edges: savedEdges } = savedWorkflow;

        if (viewport) {
          const { x = 0, y = 0, zoom = 1 } = viewport;
          setViewport({ x, y, zoom });
        }

        if (savedNodes) {
          setNodes(savedNodes);
        }

        if (savedEdges) {
          setEdges(savedEdges);
        }
      } catch (error) {
        console.error('Error parsing saved workflow:', error);
      }
    };

    restoreFlow();
  }, [setNodes, setEdges, setViewport, id]);

  const onAddConnectionRequest = useCallback(() => {
    const newNode = {
      id: getNodeId(),
      data: { label: 'Send connection request' },
      position: { x: 300, y: 100 },
    };
    setNodes((nds) => nds.concat(newNode));
  }, [setNodes]);

  const onAddProfileView = useCallback(() => {
    const newNode = {
      id: getNodeId(),
      data: { label: 'View profile' },
      position: { x: 300, y: 100 },
    };
    setNodes((nds) => nds.concat(newNode));
  }, [setNodes]);

  const onAddPauseFlow = useCallback(() => {
    const newNode = {
      id: getNodeId(),
      data: { label: 'Timer' },
      position: { x: 300, y: 100 },
    };
    setNodes((nds) => nds.concat(newNode));
  }, [setNodes]);

  const onAddSendMessage = useCallback(() => {
    const newNode = {
      id: getNodeId(),
      data: { label: 'Send message' },
      position: { x: 300, y: 100 },
    };
    setNodes((nds) => nds.concat(newNode));
  }, [setNodes]);

  useEffect(() => {
    onRestore();
  }, [onRestore]);

  const saveConfiguredWorkflow = async ({ workflow, id }) => {
    try {
      await api.post(`/campaigns/${id}/configure-workflow`, {
        configuredWorkflow: workflow,
      });
      console.log('Configured workflow saved to the campaign:');
    } catch (error) {
      console.error('Error saving configured workflow:', error.response.data);
    }
  };

  const getConfiguredWorkflow = async (id) => {
    try {
      const response = await api.get(`/campaigns/${id}`);
      return response.data.configuredWorkflow;
    } catch (error) {
      console.error('Error fetching configured workflow:', error.response.data);
      return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNodeSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setNodeSettings((prevSettings) => ({
      ...prevSettings,
      [name]: checked,
    }));
  };

  const handleSaveSettings = () => {
    setNodes((currentNodes) =>
      currentNodes.map((node) => {
        if (node.id === selectedNode.id) {
          return {
            ...node,
            data: {
              ...node.data,
              ...nodeSettings,
            },
          };
        }
        return node;
      })
    );
    setShowSuccessMessage(true);
  };

  const handleInsertDynamicField = (field) => {
    setNodeSettings((prevSettings) => {
      const message = prevSettings.message || '';
      if (!message.includes(field)) {
        return {
          ...prevSettings,
          message: message + field,
        };
      }
      return prevSettings;
    });
  };

  const renderDynamicFieldsDropdown = () => (
    <Dropdown>
      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
        Add dynamic field
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => handleInsertDynamicField('{{name}}')}>
          Lead Name
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleInsertDynamicField('{{company}}')}>
          Company Name
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleInsertDynamicField('{{job_title}}')}>
          Job Title
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  const renderNodeSettings = () => {
    if (!selectedNode) return null;

    const { label } = nodeSettings;

    switch (label) {
      case 'Timer':
        return (
          <Form>
            <Form.Group controlId="timerDuration">
              <Form.Label>Set wait time (in minutes)</Form.Label>
              <Form.Control
                type="number"
                name="waitTime"
                value={nodeSettings.waitTime || ''}
                onChange={handleChange}
              />
            </Form.Group>
            <Button variant="primary" onClick={handleSaveSettings}>Save</Button>
            {showSuccessMessage && <div className="success-message">Saved successfully!</div>}
          </Form>
        );
      case 'Send connection request':
        return (
          <Form>
            <Form.Group controlId="connectionMessage">
              <Form.Check
                type="checkbox"
                name="includeMessage"
                label="Include a message"
                checked={nodeSettings.includeMessage || false}
                onChange={handleCheckboxChange}
              />
              {nodeSettings.includeMessage && (
                <div>
                  {renderDynamicFieldsDropdown()}
                  <Form.Label>Message to send</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="message"
                    value={nodeSettings.message || ''}
                    onChange={handleChange}
                  />
                </div>
              )}
            </Form.Group>
            <Button variant="primary" onClick={handleSaveSettings}>Save</Button>
            {showSuccessMessage && <div className="success-message">Saved successfully!</div>}
          </Form>
        );
      case 'Send message':
        return (
          <Form>
            <Form.Group controlId="sendMessage">
              <Form.Label>Message to send</Form.Label>
              {renderDynamicFieldsDropdown()}
              <Form.Control
                as="textarea"
                name="message"
                value={nodeSettings.message || ''}
                onChange={handleChange}
              />
            </Form.Group>
            <Button variant="primary" onClick={handleSaveSettings}>Save</Button>
            {showSuccessMessage && <div className="success-message">Saved successfully!</div>}
          </Form>
        );
      default:
        return <p>No settings available for this node.</p>;
    }
  };

  return (
    <div className="reactflow-wrapper" style={{ width: '100%', height: '70vh' }} ref={reactFlowWrapper}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onNodeClick={onNodeClick}
        onInit={setRfInstance}
        zoomOnScroll={false}
        panOnScroll={false}
        panOnDrag={false}
        zoomOnDoubleClick={false}
        minZoom={1}
        maxZoom={1}
      >
        <Panel position="top-right" className='panel-workflow'>
          <h3>Add action</h3>
          <NavDropdown
            title="Select node"
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item onClick={onAddConnectionRequest}>
              Connection request
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={onAddProfileView}>
              Profile view
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={onAddSendMessage}>
              Send message
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={onAddPauseFlow}>
              Add timer
            </NavDropdown.Item>
          </NavDropdown>
     
          <hr />
     
          {selectedNode && (
            <div>
              <h3>Node settings</h3>
              {renderNodeSettings()}
            </div>
          )}
        </Panel>
        <Panel position="bottom-right" className='panel-workflow'>
          <Button onClick={onSave}>Save workflow</Button>
          <Button onClick={onRestore}>Undo changes</Button>
        </Panel>
      </ReactFlow>
    </div>
  );
};

export default SaveRestore;
