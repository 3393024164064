// src/components/SubscriptionRedirect.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Correct import
import Userfront from '@userfront/toolkit/react';
import api from './../api/axios'; // Import your axios instance

const SubscriptionRedirect = () => {
  const [loading, setLoading] = useState(true);
  const [trialStatus, setTrialStatus] = useState('');
  const navigate = useNavigate(); // Correct usage

  useEffect(() => {
    const checkTrialStatus = async () => {
      try {
        const userEmail = Userfront.user.email;
        if (!userEmail) {
          console.error('User email is not available.');
          setLoading(false);
          return;
        }

        const response = await api.get(`/check-trial-status?email=${userEmail}`);
        const status = response.data.trialStatus;
        setTrialStatus(status);

        if (status === 'Active') {
          // Redirect to subscription page if trial is active
          navigate('/trial-ended');
        }

        setLoading(false);
      } catch (error) {
        console.error('Error checking trial status:', error);
        setLoading(false);
      }
    };

    checkTrialStatus();
  }, [navigate]); // Dependency

  if (loading) {
  //  return <div>Loading...</div>;
  }

  return null;
};

export default SubscriptionRedirect;
