import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Userfront from '@userfront/toolkit/react';
import Sidebar from './../../components/Sidebar';
import NavBar from '../../components/Navbar';
import axios from 'axios';
import './Home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faComment, faDollarSign, faEye, faPaperPlane, faBullseye, faUsers, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { usePersistentCollapsedState } from './../../components/SidebarContext';
import MixedChart from '../../components/LogChart';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import api from './../../api/axios'; // Import your axios instance

const Home = () => {
  const [logs, setLogs] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [activeCampaigns, setActiveCampaigns] = useState([]);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0); // New state for unread messages count
  const [isDataLoaded, setIsDataLoaded] = useState(false); // State to check if data is loaded
  const currentUser = Userfront.user.email;
  const [tooltips, setTooltips] = useState({});
  const [totalLeadsCount, setTotalLeadsCount] = useState(0);
  const [totalNewLeadsCount, setTotalNewLeadsCount] = useState(0);
  const [newConnections, setNewConnections] = useState(0); // New connections state
  const [newConnectionsThisWeek, setNewConnectionsThisWeek] = useState(0); // New connections this week
  const [percentageConnectionsChange, setPercentageConnectionsChange] = useState(0);
  
  const [activeCampaignsCount, setActiveCampaignsCount] = useState(0);
  const [previousLeadsCount, setPreviousLeadsCount] = useState(0);
  const [percentageChange, setPercentageChange] = useState(0);
  const [percentageChangeClass, setPercentageChangeClass] = useState(''); // State to hold the class for percentage change

  useEffect(() => {
    fetchData();
    fetchUnreadMessagesCount(); // Fetch unread messages count on component mount
    fetchActiveCampaigns();
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is authenticated and email is confirmed
    if (!Userfront.user.isConfirmed) {
      // navigate("/confirmation");
    }
  }, [navigate]);

  const fetchData = async () => {
    try {
      // Fetch current total leads
      const leadsResponse = await api.get('/leads', { params: { user: currentUser } });
      const totalLeadsCount = leadsResponse.data.length;
  
      // Fetch total leads for comparison (e.g., last week)
      // const lastWeekEndDate = new Date(Date.now() - 7*24*60*60*1000).toISOString();


      // Get today's date
      const today = new Date();

      // Calculate the last Sunday before today
      const lastSunday = new Date(today.setDate(today.getDate() - today.getDay()));

      // Convert the last Sunday to an ISO string
      const lastWeekEndDate = lastSunday.toISOString();

      const totalLeadsPreviousResponse = await api.get('/leads/total', { 
        params: { user: currentUser, endDate: lastWeekEndDate } 
      });
      const totalLeadsPreviousCount = totalLeadsPreviousResponse.data.total;
  
      const totalNewLeadsCount = totalLeadsCount - totalLeadsPreviousCount;
      // Calculate percentage change
      const percentageChange = totalLeadsPreviousCount === 0 ? 
        (totalLeadsCount === 0 ? 0 : 100) : 
        ((totalLeadsCount - totalLeadsPreviousCount) / totalLeadsPreviousCount) * 100;
  
      setTotalLeadsCount(totalLeadsCount);
      setTotalNewLeadsCount(totalNewLeadsCount);
      setPreviousLeadsCount(totalLeadsPreviousCount);
      setPercentageChange(percentageChange.toFixed(2));
      setPercentageChangeClass(percentageChange >= 0 ? 'percentage-positive' : 'percentage-negative');
  
      // Fetch other data
      const [logsResponse, campaignsResponse] = await Promise.all([
        api.get(`/logs/${currentUser}`),
        api.get(`/campaigns`),
      ]);


      const newConnections = logsResponse.data.filter(log => log.action === 'requestAccepted');

      // Count new connections this week
      const newConnectionsThisWeek = newConnections.filter(log => new Date(log.timestamp) > new Date(lastWeekEndDate)).length;

      // Set new connections data
    setNewConnections(newConnections.length);
    setNewConnectionsThisWeek(newConnectionsThisWeek);

  
      const filteredCampaigns = campaignsResponse.data.filter(campaign => 
        campaign.status === 'Active' || campaign.status === 'Concept'
      );
  
      const filteredLogs = logsResponse.data.filter(log => log.action !== 'waitTime');
      setLogs(filteredLogs.slice(-10));
      setCampaigns(filteredCampaigns);
  
      setIsDataLoaded(true);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLogs([]);
      setCampaigns([]);
      setTotalLeadsCount(0);
      setPercentageChange(0);
    }
  };

  const fetchActiveCampaigns = async () => {
    try {
      const campaignsResponse = await api.get(`/campaigns`);
  
      // Filter campaigns to include only "Active," "Concept," or "Paused" status
      const filteredCampaigns = campaignsResponse.data.filter(campaign =>
        campaign.status === 'Active' || campaign.status === 'Concept' || campaign.status === 'Paused'
      );
  
      // Sort campaigns by createdAt date in descending order
      const sortedCampaigns = filteredCampaigns.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  
      // Take the first 5 campaigns from the sorted array
      const lastFiveCampaigns = sortedCampaigns.slice(0, 5);
  
      // Set campaigns data as an array of the last 5 campaigns
      setActiveCampaigns(lastFiveCampaigns);
  
      // Count the number of active campaigns
      const activeCount = lastFiveCampaigns.filter(campaign => campaign.status === 'Active').length;
      setActiveCampaignsCount(activeCount);
    } catch (error) {
      console.error('Error fetching data:', error);
      setActiveCampaigns([]);
      setActiveCampaignsCount(0);
    }
  };
  

  const fetchUnreadMessagesCount = async () => {
    try {
      const response = await api.get(`/messages/unread-count`);
      setUnreadMessagesCount(response.data.unreadCount); // Set unread messages count
    } catch (error) {
      console.error('Error fetching unread messages count:', error);
      setUnreadMessagesCount(0);
    }
  };

  const collapsed = usePersistentCollapsedState();

  const generateTableData = () => {
    if (!logs.length || !isDataLoaded) {
      console.log('Logs data is empty or data not loaded yet');
      return [];
    }

    return logs.map((log, index) => ({
      id: index,
      activity: log.action,
      contact: log.leadName || 'Unknown',
      teamMember: log.userId,
      date: new Date(log.timestamp).toLocaleString([], { dateStyle: 'short', timeStyle: 'short' }), // Format date and time together
      campaignTitle: log.campaignTitle || 'Unknown Campaign',
    }));
  };

  const getActivityIcon = (activity) => {
    switch (activity) {
      case 'viewProfile':
        return <FontAwesomeIcon icon={faEye} />;
      case 'sendConnectionRequest':
        return <FontAwesomeIcon icon={faPaperPlane} />;
      default:
        return <FontAwesomeIcon icon={faUser} />;
    }
  };

  const getActivityDescription = (activity) => {
    switch (activity) {
      case 'viewProfile':
        return 'Viewed Profile';
      case 'sendConnectionRequest':
        return 'Sent Connection Request';
      // Add more cases as needed
      default:
        return 'Unknown Activity';
    }
  };

  const toggleTooltip = (id) => {
    setTooltips({ ...tooltips, [id]: !tooltips[id] });
  };

  const getStatusLabelClass = (status) => {
    switch (status) {
      case 'Concept':
        return 'status-label-concept';
      case 'Active':
        return 'status-label-active';
      case 'Paused':
        return 'status-label-paused';
      case 'Completed':
        return 'status-label-completed';
      case 'Stopped':
        return 'status-label-stopped';
      case 'Archived':
        return 'status-label-archived';
      default:
        return '';
    }
  };

  const renderTooltip = (user, props) => (
    <Tooltip id="button-tooltip" className='custom-tooltip' {...props}>
      {user}
    </Tooltip>
  );

  const calculateReplyRate = (leads) => {
    if (!Array.isArray(leads) || leads.length === 0) return 0;
    const repliedLeads = leads.filter(lead => lead.replied).length;
    return ((repliedLeads / leads.length) * 100).toFixed(2);
  };

  const calculateAcceptanceRate = (leads) => {
    if (!Array.isArray(leads) || leads.length === 0) return 0;
    const acceptedLeads = leads.filter(lead => lead.accepted).length;
    return ((acceptedLeads / leads.length) * 100).toFixed(2);
  };
  

  return (
    <div className={`dashboard ${collapsed ? 'collapsed' : ''}`}>
      <Sidebar />
      <div className="container">
        <NavBar />
        <div className="row statsCardRow">
          <div className="col-md-3">
            <div className="stats-card stats-card-primary rounded-sm border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
                <FontAwesomeIcon icon={faUsers} className="stats-icon" />
              </div>
              <h2 className='stats-card-value'>{totalLeadsCount}</h2>
              <p className='stats-span font-medium text-sm'>Total leads 
                <span className={`stats-span-percentage ${percentageChangeClass}`}> {totalNewLeadsCount} this week</span>
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="stats-card stats-card-primary rounded-sm border bg-white py-6 px-7.5 shadow-default">
              <div className="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-meta-2">
                <FontAwesomeIcon icon={faUserPlus} className="stats-icon" />
              </div>
              <h2 className='stats-card-value'>{newConnections}</h2>
              <p className='stats-span font-medium text-sm '>New connections 
              <span className={`stats-span-percentage ${percentageChangeClass}`}>{newConnectionsThisWeek} this week</span>
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="stats-card stats-card-primary rounded-sm border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
                <FontAwesomeIcon icon={faEnvelope} className="stats-icon" />
              </div>
              <h2 className='stats-card-value'>{unreadMessagesCount}</h2>
              <p className='stats-span font-medium text-sm'>Unread messages</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="stats-card stats-card-primary rounded-sm border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
                <FontAwesomeIcon icon={faBullseye} className="stats-icon" />
              </div>
              <h2 className='stats-card-value'>{activeCampaignsCount}</h2>
              <p className='stats-span font-medium text-sm'>Active campaigns</p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="activities-widget activities-widget-home">
              <MixedChart />
            </div>
          </div>

          <div className="col-md-6">
            <div className="activities-widget activities-widget-home">
              <div className="header-container header-container-home">
                <h2>Recent campaigns</h2>
                <a href="/campaigns" className='btn btn-primary'>All campaigns</a>
              </div>
              <div className='table-responsive'>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Campaign</th>
                      <th>Status</th>
                      <th>Acceptance rate</th>
                      <th>Reply rate</th>
                      <th>User</th>
                      {/* Add more headers as needed */}
                    </tr>
                  </thead>
                  <tbody>
                    {activeCampaigns.map((campaign, index) => (
                      <tr key={index}>
                        <td>{campaign.title}</td>
                        <td>
                          <div className={`status-label-container ${getStatusLabelClass(campaign.status)}`}>
                            {campaign.status}
                          </div>
                        </td>
                        <td>{calculateAcceptanceRate(campaign.leads)}%</td>
                        <td>{calculateReplyRate(campaign.leads)}%</td>
                        <td>
                          <OverlayTrigger
                            placement="top" // You can adjust the placement to 'bottom', 'left', 'right', etc.
                            overlay={renderTooltip(campaign.user)}
                          >
                            <div className="user-initial">{currentUser?.charAt(0).toUpperCase()}</div>
                          </OverlayTrigger>
                        </td>
                        {/* Add more columns based on your campaign data */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="activities-widget widget-margin-bottom activities-widget-home">
              <div className="header-container header-container-home">
                  <h2>Recent activities</h2>
                </div>
              <div className='table-responsive'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th>Activity</th>
                      <th>Lead</th>
                      <th>Campaign</th>
                      <th>Team Member</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {generateTableData().map((row, index) => (
                      <tr key={index}>
                        <td>
                          <div id={`activity-${index}`}>
                            {getActivityIcon(row.activity)} 
                            {getActivityDescription(row.activity)}
                          </div>
                        </td>
                        <td>{row.contact}</td>
                        <td>{row.campaignTitle}</td>
                        <td>
                          <div className="team-member-icon">
                            {row.teamMember}
                          </div>
                        </td>
                        <td>{row.date}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
