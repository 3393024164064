import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import Userfront from '@userfront/toolkit/react';
import api from './../api/axios'; // Import your axios instance
import './LogChart.css'; // Import your CSS file

import { faCog, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MixedChart = () => {
  const [interval, setInterval] = useState('7days');
  const [logs, setLogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedActions, setSelectedActions] = useState(['viewProfile', 'sendConnectionRequest', 'messageReceived', 'messageSent', 'requestAccepted']);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [showFilters, setShowFilters] = useState(false); // Control filter visibility
  const [dropdownStates, setDropdownStates] = useState({ interval: false, user: false, action: false });

  const currentUser = Userfront.user.email;

  useEffect(() => {
    fetchOrganizationUsers();
  }, []);

  useEffect(() => {
    fetchLogs();
  }, [interval, selectedUser]);

  useEffect(() => {
    filterLogsByActionType();
  }, [selectedActions, logs]);

  const fetchOrganizationUsers = async () => {
    try {
      const response = await api.get(`/organization-users?email=${currentUser}`);
      setUsers(response.data.users);
    } catch (error) {
      console.error('Error fetching organization users:', error);
    }
  };

  const fetchLogs = async () => {
    try {
      const userToFetch = selectedUser || currentUser;
      const response = await api.get(`/logs/${userToFetch}`);
      setLogs(response.data);
      setFilteredLogs(response.data); // Initially, no filter applied
      const xAxisCategories = getXAxisCategories(response.data);
      setCategories(xAxisCategories);
    } catch (error) {
      console.error('Error fetching logs:', error);
      setLogs([]);
      setCategories([]);
    }
  };

  const filterLogsByActionType = () => {
    if (selectedActions.length === 0) {
      setFilteredLogs([]);
    } else {
      const filtered = logs.filter(log => selectedActions.includes(log.action));
      setFilteredLogs(filtered);
    }
  };

  const getXAxisCategories = (logsData) => {
    if (!logsData || logsData.length === 0) {
      return [];
    }

    let days = 7; // Default interval
    if (interval === '30days') {
      days = 30;
    } else if (interval === '90days') {
      days = 90;
    }

    const categories = [];
    const currentDate = new Date();
    for (let i = 0; i < days; i++) {
      const date = new Date(currentDate);
      date.setDate(currentDate.getDate() - i);
      const formattedDate = `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`;
      categories.push({ x: date.getTime(), formattedDate });
    }
    return categories.reverse();
  };

  const generateSeriesData = () => {
    if (!filteredLogs || filteredLogs.length === 0 || !categories || categories.length === 0) {
      return [];
    }

    const actionTypes = selectedActions;

    // Prepare series data for each action type
    const seriesData = actionTypes.map(action => {
      // Calculate data points for each category
      const data = categories.map(category => {
        // Count logs for the current action type and date category
        const logCount = filteredLogs.filter(log => log.action === action && isSameDay(new Date(log.timestamp), new Date(category.x))).length;

        return { x: category.x, y: logCount };
      });

      return { name: action, data };
    });

    return seriesData;
  };

  const isSameDay = (date1, date2) => {
    return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
  };

  const handleActionChange = (action) => {
    if (selectedActions.includes(action)) {
      setSelectedActions(selectedActions.filter(a => a !== action));
    } else {
      setSelectedActions([...selectedActions, action]);
    }
  };

  const handleUserChange = (user) => {
    setSelectedUser(user);
    setDropdownStates(prevStates => ({ ...prevStates, user: false })); // Hide user dropdown when a user is selected
  };

  const handleIntervalChange = (period) => {
    setInterval(period);
    setDropdownStates(prevStates => ({ ...prevStates, interval: false })); // Hide interval dropdown when a period is selected
  };

  const handleDropdownToggle = (dropdown) => {
    setDropdownStates(prevStates => ({
      ...prevStates,
      [dropdown]: !prevStates[dropdown],
    }));
  };

  const options = {
    chart: {
      id: 'mixed-chart',
      stacked: false,
      zoom: {
        enabled: true,
        type: 'x',
        autoScaleYaxis: true,
      },
    },
    xaxis: {
      type: 'datetime',
      categories: categories.map(category => category.x),
      labels: {
        formatter: function (val) {
          const category = categories.find(cat => cat.x === val);
          return category ? category.formattedDate : '';
        },
      },
    },
    stroke: {
      width: [2],
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
      },
    },
  };

  return (
    <div className="mixed-chart">
      <div className="chart-header header-container header-container-home">
        <h2>All activities</h2>
        <FontAwesomeIcon 
          icon={faCog} 
          className="cog-icon-button" 
          onClick={() => setShowFilters(!showFilters)} 
        />
      </div>
      {showFilters && (
        <div className="controls">
          <span className='graph-filter-span font-medium'>Filters: </span>
          {/* Interval Dropdown */}
          <div className="dropdown interval-selector">
            <button className="dropdown-button btn-chart-filter" onClick={() => handleDropdownToggle('interval')}>
              Period <FontAwesomeIcon icon={faCaretDown} />
            </button>
            <div className={`dropdown-content ${dropdownStates.interval ? 'show' : ''}`}>
              <button onClick={() => handleIntervalChange('7days')}>Last 7 Days</button>
              <button onClick={() => handleIntervalChange('30days')}>Last 30 Days</button>
              <button onClick={() => handleIntervalChange('90days')}>Last 90 Days</button>
            </div>
          </div>

          {/* User Selector Dropdown */}
          <div className="dropdown user-selector">
            <button className="dropdown-button btn-chart-filter" onClick={() => handleDropdownToggle('user')}>
              Members <FontAwesomeIcon icon={faCaretDown} />
            </button>
            <div className={`dropdown-content ${dropdownStates.user ? 'show' : ''}`}>
              <button onClick={() => handleUserChange('')}>All Users</button>
              {users.map(user => (
                <button key={user._id} onClick={() => handleUserChange(user.email)}>{user.name}</button>
              ))}
            </div>
          </div>

          {/* Action Type Dropdown */}
          <div className="dropdown action-selector">
            <button className="dropdown-button btn-chart-filter" onClick={() => handleDropdownToggle('action')}>
              Actions <FontAwesomeIcon icon={faCaretDown} />
            </button>
            <div className={`dropdown-content ${dropdownStates.action ? 'show' : ''}`}>
              <div className="multi-select">
                {['viewProfile', 'sendConnectionRequest', 'messageReceived', 'messageSent', 'requestAccepted'].map(action => (
                  <label key={action}>
                    <input
                      type="checkbox"
                      checked={selectedActions.includes(action)}
                      onChange={() => handleActionChange(action)}
                    />
                    {action}
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      
      <ReactApexChart options={options} series={generateSeriesData()} type="line" height={350} />
    </div>
  );
};

export default MixedChart;
