import React, { useState, useEffect } from 'react';
import Userfront from '@userfront/toolkit/react';
import './Team.css';
import Sidebar from './../../components/Sidebar';
import NavBar from './../../components/Navbar';
import { Modal, Button, Form } from 'react-bootstrap';
import api from './../../api/axios';

const Team = () => {
  const [users, setUsers] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [inviteData, setInviteData] = useState({ name: '', email: '' });
  const [availableSeats, setAvailableSeats] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      await fetchOrganizationUsers();
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (users.length > 0) {
      updateAvailableSeats();
    }
  }, [users]);

  const fetchOrganizationUsers = async () => {
    try {
      const email = Userfront.user.email;
      const response = await api.get(`/organization-users`, { params: { email } });
      const users = response.data.users;
      setUsers(users);

      const currentUser = Userfront.user;
      const isAdminUser = users.some(user => {
        return String(user.email) === String(currentUser.email) && user.role === 'Admin';
      });

      setIsAdmin(isAdminUser);
    } catch (error) {
      console.error('Error fetching organization users:', error);
    }
  };

  const updateAvailableSeats = async () => {
    try {
      const email = Userfront.user.email;
      const subscriptionsResponse = await api.get(`/subscriptions?email=${email}`);
      const activeSubscriptions = subscriptionsResponse.data.subscriptions;

      const totalSeatsBought = activeSubscriptions.reduce((total, sub) => total + sub.quantity, 0);
      const seatsInUse = users.filter(user => user.subscriptionStatus === 'Active').length;
      const available = Math.max(0, totalSeatsBought - seatsInUse);
      setAvailableSeats(available);
    } catch (error) {
      console.error('Error calculating available seats:', error);
      setAvailableSeats(0);
    }
  };

  const handleInvite = async () => {
    try {
      const currentUserEmail = Userfront.user.email;
      const userResponse = await api.get(`/user?email=${currentUserEmail}`);
      const currentUser = userResponse.data.user;

      await api.post('/invite-user', {
        email: inviteData.email,
        name: inviteData.name,
        role: 'Member',
        organizationId: currentUser.organizationId,
      });

      setShowInviteModal(false);
      alert('User invited successfully!');
    } catch (error) {
      console.error('Error inviting user:', error);
      alert('Invitation failed. Please try again.');
    }
  };

  const handleChange = (e) => {
    setInviteData({
      ...inviteData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectUser = (email, currentStatus) => {
    setSelectedUser(email);
    setSelectedStatus(currentStatus === 'Active' ? 'Inactive' : 'Active'); // Automatically set opposite status
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const handleConfirmStatus = async () => {
    if (!selectedUser) {
      alert('No user selected.');
      return;
    }

    if (availableSeats <= 0 && selectedStatus === 'Active') {
      alert('No seats left, update subscription.');
      return;
    }

    try {
      await api.put('/update-subscription-status', {
        email: selectedUser,
        subscriptionStatus: selectedStatus,
      });

      alert('Subscription status updated successfully!');
      setSelectedUser(null);
      setSelectedStatus('');
      await fetchOrganizationUsers();
    } catch (error) {
      console.error('Error updating subscription status:', error);
      alert('Failed to update subscription status. Please try again.');
    }
  };

  return (
    <div className="dashboard">
      <Sidebar />
      <div className="container">
        <NavBar />
        <div className="row">
          <div className="col-md-12">
            <div className='activities-widget'>
              <div className="header-container">
                <h2>Team</h2>
                {isAdmin && availableSeats !== null && (
                  <div className='header-info'>
                    <div className="available-seats-container">
                      <span className="available-seats-label">Available Seats:</span>
                      <span
                        className={`available-seats-text ${availableSeats === 0 ? 'text-danger' : 'text-success'}`}
                      >
                        {availableSeats}
                      </span>
                    </div>
                    {availableSeats > 0 && (
                      <button onClick={() => setShowInviteModal(true)} className="btn btn-primary">
                        Invite User
                      </button>
                    )}
                  </div>
                )}
              </div>
              <div className='table-responsive'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th>Status</th>
                      {isAdmin && <th>Actions</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user) => (
                      <tr key={user.userId}>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.role}</td>
                        <td>
                          <div
                            className={`status-label-container ${
                              user.subscriptionStatus === 'Active' ? 'status-label-active' : 'status-label-inactive'
                            }`}
                          >
                            {user.subscriptionStatus}
                          </div>
                        </td>
                        {isAdmin && (
                          <td>
                            {selectedUser === user.email ? (
                              <>
                                <Form.Select
                                  value={selectedStatus}
                                  onChange={handleStatusChange}
                                  style={{ marginBottom: '10px' }}
                                >
                                  <option value="Active">Active</option>
                                  <option value="Inactive">Inactive</option>
                                </Form.Select>
                                <Button
                                  onClick={handleConfirmStatus}
                                  className="btn btn-primary"
                                  disabled={availableSeats <= 0 && selectedStatus === 'Active'}
                                >
                                  Confirm
                                </Button>
                              </>
                            ) : (
                              <Button
                                onClick={() => handleSelectUser(user.email, user.subscriptionStatus)}
                                variant={user.subscriptionStatus === 'Active' ? 'danger' : 'success'}
                              >
                                {user.subscriptionStatus === 'Active' ? 'Deactivate' : 'Activate'}
                              </Button>
                            )}
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <Modal show={showInviteModal} onHide={() => setShowInviteModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Invite User</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group controlId="inviteName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={inviteData.name}
                      onChange={handleChange}
                      placeholder="Enter user's name"
                    />
                  </Form.Group>
                  <Form.Group controlId="inviteEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={inviteData.email}
                      onChange={handleChange}
                      placeholder="Enter user's email"
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowInviteModal(false)}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={handleInvite}>
                  Send Invite
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
