import React, { useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import api from './../api/axios'; // Import your axios instance

const CheckoutForm = ({ email, onSuccess, showBasicPlans, activeSubscriptions }) => {
  const stripe = useStripe();
  const [plan, setPlan] = useState('');
  const [loading, setLoading] = useState(false);

  // Determine which plans should be available
  const activePlanIds = activeSubscriptions.map(sub => sub.planName.toLowerCase().replace(' ', '_'));

  // Determine if there are any available plans to display
  const hasBasicPlans = showBasicPlans && !activePlanIds.includes('basic_monthly') || !activePlanIds.includes('basic_yearly');
  const hasTeamPlans = !activePlanIds.includes('team_monthly') || !activePlanIds.includes('team_yearly');
  
  const availablePlans = [
    { id: 'basic_monthly', name: 'Basic Monthly' },
    { id: 'basic_yearly', name: 'Basic Yearly' },
    { id: 'team_monthly', name: 'Team Monthly' },
    { id: 'team_yearly', name: 'Team Yearly' },
  ].filter(plan => {
    if (plan.id.startsWith('basic_')) {
      return showBasicPlans && !activePlanIds.includes(plan.id);
    }
    return !activePlanIds.includes(plan.id);
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!plan) {
      alert('Please select a plan.');
      return;
    }

    setLoading(true);

    try {
      const response = await api.post('/create-checkout-session', {
        email,
        plan
      });

      const sessionId = response.data.sessionId;
      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        console.error('Error redirecting to Checkout:', error);
        alert('Subscription failed. Please try again.');
      }
    } catch (error) {
      console.error('Error processing subscription:', error);
      alert('Subscription failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {availablePlans.length > 0 ? (
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="plan">Select Plan:</label>
            <select id="plan" value={plan} onChange={(e) => setPlan(e.target.value)}>
              <option value="">--Select a Plan--</option>
              {availablePlans.map(plan => (
                <option key={plan.id} value={plan.id}>{plan.name}</option>
              ))}
            </select>
          </div>
          <button type="submit" disabled={!stripe || loading}>
            {loading ? 'Processing...' : 'Subscribe'}
          </button>
        </form>
      ) : (
        <p>No subscription types available. Manage the quantity of your active team subscriptions to add new seats to your monthly or yearly subscription.</p>
      )}
    </div>
  );
};

export default CheckoutForm;
