import React, { useState, useEffect } from 'react';
import './Campaigns.css';
import axios from 'axios';
import Sidebar from './../../components/Sidebar';
import { Modal, Button, Form } from 'react-bootstrap';
import NavBar from '../../components/Navbar';
import { Link } from 'react-router-dom';
import { usePersistentCollapsedState } from './../../components/SidebarContext'; // Import useSidebar

import Userfront from "@userfront/toolkit/react";
import api from './../../api/axios'; // Import your axios instance


function Campaigns() {
  const collapsed = usePersistentCollapsedState();

  const [campaigns, setCampaigns] = useState([]);
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStatusFilter, setSelectedStatusFilter] = useState('all');
  const [showModal, setShowModal] = useState(false);
  const [showStartNewCampaignModal, setShowStartNewCampaignModal] = useState(false); // Separate state for "Start New Campaign" modal
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

  const [newCampaign, setNewCampaign] = useState({
    title: '',
    description: '',
    status: 'Not Started',
  });
  const [campaignToDelete, setCampaignToDelete] = useState(null); // Track the campaign to delete

  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  const createNewCampaign = async () => {
    try {
      const user = await Userfront.user.email;

      const response = await api.post('/campaigns', {
        user: Userfront.user.email,
        title: newCampaign.title,
        description: newCampaign.description,
      });

      setCampaigns([...campaigns, response.data]);
      setFilteredCampaigns([...campaigns, response.data]);
      setShowModal(false);
      window.location.replace(`/campaigns/${response.data.result.insertedId}`);
    } catch (error) {
      console.error('Error creating campaign:', error.response.data);
    }
  };

  const deleteCampaign = async (campaignId) => {
    try {
      const response = await api.delete(`/campaigns/${campaignId}`);
      console.log(response.data);
      fetchCampaigns();
    } catch (error) {
      console.error('Error deleting campaign:', error);
    } finally {
      // Close the confirmation modal
      setShowDeleteConfirmationModal(false);
      // Clear the campaignToDelete state
      setCampaignToDelete(null);
    }
  };

  const fetchCampaigns = async () => {
    try {
      const response = await api.get('/campaigns');
      setCampaigns(response.data);
      setFilteredCampaigns(response.data);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    }
  };

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const handleManageClick = (campaignId) => {
    // Handle managing the campaign (e.g., navigate to a campaign detail page)
  };

  const handleDeleteClick = (campaignId) => {
    // Set the campaign to delete and show the confirmation modal
    setCampaignToDelete(campaignId);
    setShowDeleteConfirmationModal(true);
  };

  useEffect(() => {
    const filtered = campaigns.filter((campaign) =>
      campaign.title && campaign.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const filteredByStatus = filtered.filter((campaign) => {
      if (selectedStatusFilter !== 'all' && campaign.status !== selectedStatusFilter) {
        return false;
      }
      return true;
    });

    setFilteredCampaigns(filteredByStatus);
  }, [searchTerm, selectedStatusFilter, campaigns]);

  const handleNewCampaignSubmit = () => {
    createNewCampaign();
  };


  const getStatusLabelClass = (status) => {
    switch (status) {
      case 'Concept':
        return 'status-label-concept';
      case 'Active':
        return 'status-label-active';
      case 'Paused':
        return 'status-label-paused';
      case 'Completed':
        return 'status-label-completed';
      case 'Stopped':
        return 'status-label-stopped';
      case 'Archived':
        return 'status-label-archived';
      default:
        return '';
    }
  };



  return (
    <div className={`dashboard`}>
      <Sidebar/>
      <div className="container">
        <NavBar />
        <div className="row">
          <div className="campaigns col-md-12">
        <div className='activities-widget'>
            <h2>Campaigns</h2>
            <div className='row'>
              <div className="col-md-4">
                <div className="search-bar">
                  <input
                    className='form-control'
                    type="text"
                    placeholder="Search Campaigns"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="status-filter">
                  <select
                    className='form-control'
                    value={selectedStatusFilter}
                    onChange={(e) => setSelectedStatusFilter(e.target.value)}
                  >
                    <option hidden value="all">Filter Campaigns</option>
                    <option value="all">All Campaigns</option>
                    <option value="Active">Active</option>
                    <option value="Paused">Paused</option>
                    <option value="Concept">Concept</option>
                    <option value="Stopped">Stopped</option>
                    <option value="Completed">Completed</option>
                    <option value="Archived">Archived</option>
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="create-button">
                  <Button className='startNewCampaignButton' variant="primary" onClick={() => setShowStartNewCampaignModal(true)}>
                    Start New Campaign
                  </Button>
                </div>
              </div>
            </div>
            <div className='table-responsive'>
              <table className="table">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Progress</th>
                    <th>Status</th>
                    <th>Team Member</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCampaigns.map((campaign) => (
                    <tr key={campaign._id}>
                      <td>{campaign.title}</td>
                      <td>{campaign.description}</td>
                      <td></td>
                      <td>
                        <div className={`status-label-container ${getStatusLabelClass(campaign.status)}`}>
                            {campaign.status}
                          </div>
                      </td>
                      <td>{campaign.user}</td>
                      <td>
                        {campaign.status !== 'Completed' && (
                          <Link className='btn btn-primary manageButton' to={`/campaigns/${campaign._id}`}>Manage</Link>
                        )}
                        <button className='btn btn-danger' onClick={() => handleDeleteClick(campaign._id)}>Delete</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>

        {/* Confirmation Modal */}
        <Modal show={showStartNewCampaignModal} onHide={() => setShowStartNewCampaignModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Start New Campaign</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="title">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter campaign title"
                  value={newCampaign.title}
                  onChange={(e) => setNewCampaign({ ...newCampaign, title: e.target.value })}
                />
              </Form.Group>
              <Form.Group controlId="description">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter campaign description"
                  value={newCampaign.description}
                  onChange={(e) => setNewCampaign({ ...newCampaign, description: e.target.value })}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowStartNewCampaignModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleNewCampaignSubmit}>
              Create Campaign
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showDeleteConfirmationModal} onHide={() => setShowDeleteConfirmationModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete this campaign?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDeleteConfirmationModal(false)}>
              Cancel
            </Button>
            <Button variant="danger" onClick={() => deleteCampaign(campaignToDelete)}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Campaigns;
